import type { WritableComputedRef } from 'vue'

export const useSyncProp = <T>(
    props: any,
    key: string,
    emit: any,
    options?: {
        onGet?: (value: T) => void
        onSet?: (value: T) => void
    },
): WritableComputedRef<T> => {
    return computed<T>({
        get() {
            options?.onGet?.(props[key])

            return props[key]
        },
        set(value) {
            options?.onSet?.(value)

            emit(`update:${ key }`, value)
        },
    })
}
