<script
    lang="ts"
    setup
>
    import type { AppDropdownProps } from '~/ts/types/app'
    import { WINDOW_SAFE_AREA } from '~/constants/common'

    type Props = AppDropdownProps

    type Emit = {
        (event: 'toggled', value: boolean): void
        (event: 'opened'): void
        (event: 'closed'): void
    }

    const props = withDefaults(defineProps<Props>(), {
        contentClass: undefined,
        maxWidth: 'auto',
        maxHeight: 'auto',
        width: 300,
        height: 'auto',
        offsetY: undefined,
        offsetX: undefined,
        fromBottom: false,
        fromRight: false,
        positionFixed: false,
        withoutPadding: false,
        disabled: false,
        disableClosing: false,
        disableScroll: false,
        setActivatorToIgnoreClickOutside: undefined,
    })

    const emit = defineEmits<Emit>()

    const { $popupManager } = useNuxtApp()

    const { close, activate } = $popupManager.registerMenu(() => {
        if (props.disableClosing) {
            return
        }

        active.value = false
        activeDetails.value = undefined

        setTimeout(() => emit('closed'), 0)
    }, { active: false })

    const activatorRef = ref<HTMLDivElement>()
    const contentRef = ref<HTMLDivElement>()
    const activatorToIgnoreClickOutside = ref<HTMLElement>()

    const active = ref<boolean>(false)
    const activeDetails = ref<any>()
    const isHovered = ref<boolean>(false)

    const contentStyle = computed<string>(() => {
        return `
            --dropdown-width:${ getSizeValue(props.width) };
            --dropdown-height:${ getSizeValue(props.height) };
            --dropdown-max-width:${ getSizeValue(props.maxWidth) };
            --dropdown-max-height:${ getSizeValue(props.maxHeight) };
            --dropdown-offset-y:${ getSizeValue(props.offsetY) || (props.fromRight ? '0' : '10px') };
            --dropdown-offset-x:${ getSizeValue(props.offsetX) || (props.fromRight ? '10px' : '0') };
        `
    })

    const contentClass = computed<string>(() => {
        let classes = `
            z-[10]
            overflow-x-hidden
            max-w-[var(--dropdown-max-width)]
            max-h-[var(--dropdown-max-height)]
            w-[var(--dropdown-width)]
            h-[var(--dropdown-height)]
            bg-white
            rounded-[8px]
            shadow-xl
        `

        if (props.positionFixed) {
            classes += ' fixed'
        } else {
            classes += ' absolute'

            if (props.fromBottom) {
                classes += ' bottom-[calc(100%+var(--dropdown-offset-y))] origin-top'
            } else {
                classes += ' top-[calc(100%+var(--dropdown-offset-y))] origin-bottom'
            }

            if (props.fromRight) {
                classes += ' right-[var(--dropdown-offset-x)]'
            } else {
                classes += ' left-[var(--dropdown-offset-x)]'
            }
        }

        if (!props.withoutPadding) {
            classes += ' p-2'
        }

        if (props.disableScroll) {
            classes += ' overflow-y-hidden'
        } else {
            classes += ' overflow-y-auto'
        }

        if (props.contentClass) {
            classes += ' ' + props.contentClass
        }

        return classes
    })

    const toggle = (details?: any): void => {
        if (props.disabled) {
            return
        }

        if (active.value) {
            close()
        } else {
            open(details)
        }

        setTimeout(() => emit('toggled', active.value), 0)
    }

    const open = (details?: any): void => {
        if (props.disabled) {
            return
        }

        activeDetails.value = details
        active.value = true

        activate()

        setTimeout(() => emit('opened'), 0)
    }

    const updateActiveDetails = (value: any): void => {
        activeDetails.value = value
    }

    // setTimeout для того, щоб при спробі закриття AppModal кліком на оверлей спрацювала перевірка isMenuOpen
    // (коли відкрита модалка, а в ній відкрито дропдаун)
    onClickOutside(contentRef, () => setTimeout(close, 0), { ignore: [ activatorToIgnoreClickOutside ] })

    const setContentPosition = async (el): Promise<void> => {
        // Для коректного оновлення висоти елемента
        await new Promise(resolve => setTimeout(resolve, 0))

        const activatorRect = activatorRef.value.getBoundingClientRect()
        const offsetY = parseInt(String(props.offsetY || 0))
        const offsetX = parseInt(String(props.offsetX || 0))
        const windowHeight = document.documentElement.clientHeight - WINDOW_SAFE_AREA
        const windowWidth = document.documentElement.clientWidth - WINDOW_SAFE_AREA
        const contentHeight = el.clientHeight
        const contentWidth = el.clientWidth

        let contentTop: number

        if (props.fromBottom) {
            contentTop = Math.round(activatorRect.top - contentHeight - offsetY)
        } else {
            contentTop = Math.round(activatorRect.top + activatorRect.height + offsetY)
        }

        el.style.top = contentTop + 'px'

        let contentLeft: number

        if (props.fromRight) {
            contentLeft = Math.round(activatorRect.left + activatorRect.width - contentWidth - offsetX)
        } else {
            contentLeft = Math.round(activatorRect.left + offsetX)
        }

        el.style.left = contentLeft + 'px'

        /* Корекція позиції */

        const contentRight = windowWidth - (contentLeft + contentWidth)
        const contentBottom = windowHeight - (contentTop + contentHeight)

        if (props.fromBottom) {
            if (contentTop < 0) {
                el.style.top = WINDOW_SAFE_AREA + 'px'
            }
        } else if (contentBottom < 0) {
            el.style.top = contentTop + contentBottom + 'px'
        }

        if (props.fromRight) {
            if (contentLeft < 0) {
                el.style.left = WINDOW_SAFE_AREA + 'px'
            }
        } else if (contentRight < 0) {
            el.style.left = contentLeft + contentRight + 'px'
        }

        if (contentHeight > windowHeight) {
            el.style.top = WINDOW_SAFE_AREA + 'px'
            el.style.maxHeight = (windowHeight - (WINDOW_SAFE_AREA * 2)) + 'px'
        }

        if (contentWidth > windowWidth) {
            el.style.left = WINDOW_SAFE_AREA + 'px'
            el.style.maxWidth = (windowWidth - (WINDOW_SAFE_AREA * 2)) + 'px'
        }
    }

    const contentBeforeEnter = (el): void => {
        el.style.overflow = 'hidden'
        el.style.opacity = 0
        el.style.transform = `translateY(${ props.fromBottom ? '8px' : '-8px' })`
    }

    const contentEnter = async (el, done): Promise<void> => {
        if (props.positionFixed) {
            await setContentPosition(el)
        }

        animate({
            duration: 0.1,
            timing: animationTiming.makeEaseOut(animationTiming.circ),
            draw(progress) {
                el.style.opacity = progress
                el.style.transform = props.fromBottom
                    ? `translateY(${ 8 - (8 * progress) }px)`
                    : `translateY(${ -8 + (8 * progress) }px)`
            },
            onComplete() {
                el.style.overflow = null
                el.style.opacity = null
                el.style.transform = null

                done()
            },
        })

        done()
    }

    const contentBeforeLeave = (el): void => {
        el.style.overflow = 'hidden'
    }

    const contentLeave = (el, done): void => {
        animate({
            duration: 0.1,
            draw(progress) {
                el.style.opacity = 1 - progress
                el.style.transform = props.fromBottom
                    ? `translateY(${ (8 * progress) }px)`
                    : `translateY(${ -(8 * progress) }px)`
            },
            onComplete() {
                el.style.overflow = null
                el.style.opacity = null
                el.style.transfrom = null

                done()
            },
        })
    }

    onMounted(() => {
        if (props.setActivatorToIgnoreClickOutside) {
            activatorToIgnoreClickOutside.value = props.setActivatorToIgnoreClickOutside()
        } else {
            activatorToIgnoreClickOutside.value = activatorRef.value
        }
    })

    defineExpose({
        active,
        activeDetails,
        hovered: isHovered,
        toggle,
        open,
        close,
    })
</script>

<template>
    <div
        class="relative"
        @mouseenter.passive="isHovered = true"
        @mouseleave.passive="isHovered = false"
    >
        <div
            ref="activatorRef"
            class="w-full"
        >
            <slot
                name="activator"
                :active="active"
                :active-details="activeDetails"
                :update-active-details="updateActiveDetails"
                :hovered="isHovered"
                :toggle="toggle"
                :open="open"
                :close="close"
            />
        </div>

        <Transition
            :css="false"
            @before-enter="contentBeforeEnter"
            @enter="contentEnter"
            @before-leave="contentBeforeLeave"
            @leave="contentLeave"
        >
            <div
                v-if="active"
                key="content"
                ref="contentRef"
                :class="contentClass"
                :style="contentStyle"
            >
                <slot
                    :active="active"
                    :active-details="activeDetails"
                    :update-active-details="updateActiveDetails"
                    :hovered="isHovered"
                    :toggle="toggle"
                    :open="open"
                    :close="close"
                />
            </div>
        </Transition>
    </div>
</template>
